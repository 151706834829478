import React from 'react';
import {
  ComponentPreview,
  DontDo,
  DontDoItem,
  List,
  Link,
  PageHero,
  PageNavigation,
  PageNavigationLink,
  PageWithSubNav,
  Paragraph,
  PlatformTable,
  PlatformTableRow,
  Section,
  SectionSubhead,
} from 'docComponents';
import { Alert, Button } from 'hudl-uniform-ui-components';
import alertPreviewData from '../../../data/previews/alert.yml';
import pageHeroData from '../../../data/pages/components.yml';

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isUsesOpen: false,
      isButtonsOpen: false,
    };

    this.handleUseAlertClick = this.handleUseAlertClick.bind(this);
    this.handleButtonAlertClick = this.handleButtonAlertClick.bind(this);
  }

  handleUseAlertClick = () => {
    this.setState({ isUsesOpen: true });
    const alert = document.getElementsByClassName('uni-overlay__portal')[0];
    alert.addEventListener('click', this.handleUsesClose);
  };

  handleButtonAlertClick = () => {
    this.setState({ isButtonsOpen: true });
    const alert = document.getElementsByClassName('uni-overlay__portal')[1];
    alert.addEventListener('click', this.handleButtonsClose);
  };

  handleUsesClose = () => {
    this.setState({ isUsesOpen: false });
  };

  handleButtonsClose = () => {
    this.setState({ isButtonsOpen: false });
  };

  render() {
    return (
      <PageWithSubNav
        pageType="design"
        title="Alert"
        designCode
        subnav="components">
        <PageHero heroData={pageHeroData} tierTwo="Alert" />

        <PageNavigation>
          <PageNavigationLink>Cases</PageNavigationLink>
          <PageNavigationLink>Buttons</PageNavigationLink>
          <PageNavigationLink>Mobile</PageNavigationLink>
          <PageNavigationLink>Usage</PageNavigationLink>
          <PageNavigationLink>Microcopy</PageNavigationLink>
          <PageNavigationLink>Platform</PageNavigationLink>
        </PageNavigation>

        <Section title="Cases">
          <Paragraph>
            The two practical applications of an alert are an acknowledgement
            and a decision.
          </Paragraph>
          <ComponentPreview
            name="AlertUses"
            layout="row"
            previewData={alertPreviewData.uses}>
            <Button
              type="primary"
              text="Trigger Alert"
              onClick={this.handleUseAlertClick}
              ignoreThisComponent
            />
            <Alert isOpen={this.state.isUsesOpen} />
          </ComponentPreview>
        </Section>

        <Section title="Buttons">
          <Paragraph>
            The number of buttons is determined by the alert’s purpose. An
            acknowledgement only requires one, while the decision should offer
            two.
          </Paragraph>
          <ComponentPreview
            name="AlertButtons"
            layout="row"
            previewData={alertPreviewData.buttons}>
            <Button
              type="primary"
              text="Trigger Alert"
              onClick={this.handleButtonAlertClick}
              ignoreThisComponent
            />
            <Alert isOpen={this.state.isButtonsOpen} />
          </ComponentPreview>
          <SectionSubhead>Button Types</SectionSubhead>
          <Paragraph>
            <List>
              <li>
                For a <strong>single acknowledgement</strong>, use Primary.
              </li>
              <li>
                For a double instance where they’ve chosen to{' '}
                <strong>delete something</strong>, put Destroy on the right with
                Subtle on the left—the latter should allow them to opt out.
              </li>
              <li>
                For a double instance with <strong>no preferred action</strong>,
                use Secondary for both.
              </li>
              <li>
                For double with a <strong>clear preference</strong>, use Primary
                on the right and Subtle on the left.{' '}
              </li>
            </List>
          </Paragraph>
          <Paragraph>
            <Link href="/components/buttons/button" isDesignCodeLink>
              Our button guidelines
            </Link>{' '}
            can give you a closer look at each type and how to use them.
          </Paragraph>
        </Section>

        <Section title="Mobile">
          <SectionSubhead>Alert vs System Alert</SectionSubhead>
          <Paragraph>
            Reserve using the Uniform alert for actions that take place{' '}
            <em>in</em> the Hudl ecosystem–like adding a teammate to the roster
            or editing the final score of an event. Decisions that affect the
            physical device should make use of the operating system’s alert. For
            example, use a system alert when communicating <em>the device</em>{' '}
            is out of storage. We need to be clear to our users they are
            deleting video from their physical device and not their Hudl
            account.{' '}
          </Paragraph>
          <DontDo gridLayout="2">
            <DontDoItem
              type="dont"
              text="use a system alert when the message applies to Hudl content."
              img="alert-system-alert-dont"
            />
            <DontDoItem
              type="do"
              text="use alerts for Hudl-related decisions."
              img="alert-system-alert-do"
            />
          </DontDo>

          <SectionSubhead>Action Order</SectionSubhead>
          <Paragraph>
            When users have decisions to make in an alert, always list the
            action that will take them <em>forward</em> first.{' '}
          </Paragraph>
          <DontDo gridLayout="2">
            <DontDoItem
              type="dont"
              text="switch up the order of actions."
              img="alert-action-order-dont"
            />
            <DontDoItem
              type="do"
              text="list the action the user has chosen to take first."
              img="alert-action-order-do"
            />
          </DontDo>
        </Section>

        <Section title="Usage">
          <SectionSubhead>Not Expected</SectionSubhead>
          <Paragraph>
            An alert should never be a “next step” or part of a workflow’s
            logical progression. It should only appear when the action is
            seemingly complete.
          </Paragraph>
          <DontDo gridLayout="2">
            <DontDoItem
              type="dont"
              text="use it to provide a whole new task."
              img="alert-task-dont"
            />
            <DontDoItem
              type="do"
              text="consider the alert a follow-up rather than any sort of step."
              img="alert-task-do"
            />
          </DontDo>

          <SectionSubhead>Good for Errors</SectionSubhead>
          <Paragraph>
            Alerts are ideal for an error they should know about before going
            anywhere else. Make it clear something didn’t go as planned and they
            can/should remedy.
          </Paragraph>
          <DontDo gridLayout="2">
            <DontDoItem
              type="dont"
              text=" interrupt with an unrelated issue."
              img="alert-issue-dont"
            />
            <DontDoItem
              type="do"
              text="use the alert for errors they may not notice otherwise."
              img="alert-issue-do"
            />
          </DontDo>

          <SectionSubhead>No Dismiss</SectionSubhead>
          <Paragraph>
            Action or acknowledgement is required with all alerts—it’s why we
            removed the dismiss.
          </Paragraph>
          <Paragraph>
            One of the options can be to cancel or “never mind”. We just want to
            make sure that decision to opt out is deliberate.
          </Paragraph>

          <SectionSubhead>No Links</SectionSubhead>
          <Paragraph>
            We shouldn’t distract from the actual thing they’re trying to do. If
            there are resources worth providing, direct them via text, but don’t
            give them an additional “out” via hyperlink.
          </Paragraph>
          <DontDo gridLayout="2">
            <DontDoItem
              type="dont"
              text="link off and prevent them from making the very important decision."
              img="alert-link-dont"
            />
            <DontDoItem
              type="do"
              text="mention resources only if they’d make a difference."
              img="alert-link-do"
            />
          </DontDo>
          <Paragraph>
            You could also risk the user interpreting a button as a way to that
            destination. (Not great when deleting.)
          </Paragraph>
        </Section>

        <Section title="Microcopy">
          <SectionSubhead>Body Only</SectionSubhead>
          <Paragraph>
            Unlike the modal, the alert has no title or subtitle. We jump
            straight to the body to ensure they read everything all at once.
          </Paragraph>
          <DontDo gridLayout="2">
            <DontDoItem
              type="dont"
              text="be cryptic and dance around the alert’s purpose."
              img="alert-clarity-dont"
            />
            <DontDoItem
              type="do"
              text="use complete sentences and make sure the content flows into the button copy."
              img="alert-clarity-do"
            />
          </DontDo>
          <Paragraph>
            (And by “flow” we mean{' '}
            <Link href="/words/content-elements/calls-to-action">
              congruence
            </Link>
            .)
          </Paragraph>

          <SectionSubhead>Content Length</SectionSubhead>
          <Paragraph>
            The body of the alert should be no longer than 150 characters long.
            If you can make it shorter, even better. Just shoot for two lines
            max.
          </Paragraph>
          <Paragraph>
            Get straight to the point and provide their options—don’t forget we
            just interrupted their workflow.
          </Paragraph>
          <DontDo gridLayout="2">
            <DontDoItem
              type="dont"
              text="write a novel explaining the cause and what they should do."
              img="alert-length-dont"
            />
            <DontDoItem
              type="do"
              text="tell them what’s up and provide a course of action."
              img="alert-length-do"
            />
          </DontDo>

          <SectionSubhead>Buttons</SectionSubhead>
          <Paragraph>
            The button should very clearly state its action. Avoid complete
            sentences, opt for a strong verb phrase instead. Title case always.
          </Paragraph>
          <Paragraph>
            You can read more about button microcopy in our{' '}
            <Link href="/components/buttons/button" isDesignCodeLink>
              button guidelines
            </Link>
            .
          </Paragraph>
        </Section>

        <Section title="Platform">
          <PlatformTable>
            <PlatformTableRow platform="web" />
            <PlatformTableRow platform="apple" />
            <PlatformTableRow platform="android" />
          </PlatformTable>
        </Section>
      </PageWithSubNav>
    );
  }
}
